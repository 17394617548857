/*baisic*/
html{
  -webkit-locale: "en";
  direction: ltr;
  unicode-bidi: isolate;
  min-height: 100%;
}
html, body{
  min-height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}
body{
  font: 400 16px/1.2 'PT Root UI';
  color: #000;
  background: #fff;
  min-width: 310px; 
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

/*clearfix*/
.clearfix{
  zoom: 1;
  &:before,
  &:after{
    content: '';
    display: table;
  }
  &:after{clear: both;}
}

/*visually hidden*/
.visually-hidden{
  position: absolute;
  visibility: 1px;
  height: 1px;
  margin: -1px;
  border: none;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/*box-sizing*/
*, *:before, *:after, input[type="search"]{
  box-sizing: border-box;
}

/*buttons*/
.btn{
  @include btn-reset;
  &--primery{
    background-color: #2F66F2;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  &--secondary{
    border: 1px solid rgba(47, 102, 242, .6);
    font-weight: 700;
    color: #6490FF;
    text-align: center;
  }
  &--md{
    font-size: 16px;
    line-height: 44px;
    letter-spacing: .25px;
    height: 44px;
    padding: 0 20px;
    border-radius: 22px;
  }
  &--lg{
    font-size: 20px;
    line-height: 56px;
    letter-spacing: .15px;
    height: 56px;
    padding: 0 24px;
    border-radius: 28px;
  }
}

/*layout*/
.wrapper{
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;	
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  & > *{width: 100%;}
}
.main{flex-grow: 1;}

.container{
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1200px;
  @media only screen and (max-width : 1024px){
    padding: 0 10px;
  }
}

/*typographyc*/
ol,ul,li,h1,h2,h3,h4,h5,h6,p,q,figure,figcaption,blockquote,cite,fieldset,hr{    
  margin:0;
  padding: 0;    
  border: none;
}
ul li, ol li{list-style: none;}
a{
  text-decoration: none;
  outline: none;
  transition: .25s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:-moz-any-link:focus ,
  &:-moz-focusring,
  &::-moz-focus-inner,
  &::-moz-focus-outer{
    outline: 0!important;
    outline: none;
  }
  &:active {outline: none;}
  &[href^="tel:"],
  &[href^="mailto:"]{white-space: nowrap;}
}
img{
  display: block;
  max-width: 100%;
  height: auto;
}
.sec-title{
  font-weight: 700;
  font-size: 44px;
  line-height: 1.18;
  letter-spacing: .25px;
  @media only screen and (max-width : 479px){
    font-size: 36px;
  }
}
