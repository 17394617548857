.header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  &__container{
    padding: 10px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, .12);
  }
  &__wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__bar{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__burger{display: none;}
  @media only screen and (max-width : 960px){
    &__container{padding: 10px 8px 10px 16px;}
    &__burger{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
    }
    &__bar{display: none;}
  }
}
.main-menu{
  margin-right: 40px;
  ul{
    display: flex;
    align-items: center;
    justify-content: center;
    li{
      margin: 0 12px;
      &:first-child{margin-left: 0;}
      &:last-child{margin-right: 0;}
      a{
        color: #fff;
        opacity: .56;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: .25px;
        text-decoration: underline;
        white-space: nowrap;
        &:hover{
          opacity: 1;
        }
      }
    }
  }
  @media only screen and (max-width : 960px){
    margin-right: 0;
    ul{
      display: block;      
      li{
        margin: 0;
        &:not(:first-child) {
          border-top: 1px solid rgba(255,255,255,.08);
          margin-top: 5px;
          padding-top: 5px;
        }
        a{
          display: block;
          padding-top: 10px;
          padding-bottom: 10px;
          text-decoration: none;
        }
      }
    }
  }
}
.mob-menu{
  display: none;
  @media only screen and (max-width : 960px){
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    z-index: 900;
    background-color: #1b213f;
    transition: transform .25s;
    padding: 10px;
    transform: translateX(101%);
    &.open{transform: translateX(0);}
    &__header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
    }
    &__main{
      margin: 40px 0;
      flex-grow: 1;
    }
    &__bar{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
    }
  }
}
.soc{
  display: flex;
  align-items: center;
  justify-content: center;
  li{    
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      opacity: .48;
      &:hover{opacity: 1;}
    }
  }
  &--footer{
    margin: 0;
    li{
      margin: 0 5px;
      &:first-child{margin-left: 0;}
      &:last-child{margin-right: 0;}
      a{opacity: 1;}
    }
  }
}
.lang{
  margin: 0 24px;
  position: relative;
  &__main{
    width: 38px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .25px;
    color: #fff;
    cursor: pointer;
    &::after{
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url(../img/svg/drop.svg) center center no-repeat;
      width: 10px;
      height: 5px;
    }
  }
  &__drop{
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -1px;
    padding-top: 5px;
    ul{
      padding: 4px;
      background: rgba(255, 255, 255, .08);
      backdrop-filter: blur(64px);
      border-radius: 8px;
    }
  }
  &__item{
    display: block;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .5px;
    color: #fff;
    width: 56px;
  }
  &:hover{
    .lang__drop{display: block;}
  }  
  @media only screen and (max-width : 960px){
    margin: 0 12px;
  }
  
}

.hero{
  background: radial-gradient(100% 100% at 0% 0%, rgba(99, 53, 240, 0.24) 0%, rgba(99, 53, 240, 0) 100%), radial-gradient(50% 100% at 100% 100%, rgba(99, 53, 240, 0.24) 0%, rgba(99, 53, 240, 0) 100%), #0C0C0C;
  position: relative;
  color: #fff;
  text-align: center;
  overflow: hidden;
  &__wrap{padding: 154px 0 96px;}
  &::after{
    content: '';
    display: block;
    position: absolute;
    top: calc(100% - 60px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    width: 11000px;
    height: 11000px;
  }
  &__title{
    margin-bottom: 28px;
    font-weight: 700;
    font-size: 52px;
    line-height: 60px;
    text-align: center;
    letter-spacing: .25px;
  }
  &__txt{
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: .15px;
    margin-bottom: 80px;
  }
  &__bar{
    display: flex;
    align-items: center;
    justify-content: center;
    & > *{
      margin: 0 12px;
      &:first-child{margin-left: 0;}
      &:last-child{margin-right: 0;}
    }
  }
  &__logotyps{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 132px;
    & > *{
      margin: 0 32px;
      &:first-child{margin-left: 0;}
      &:last-child{margin-right: 0;}
    }
  }
  &__logo{display: block;}
  @media only screen and (max-width : 960px){
    &__wrap{padding: 112px 0 86px;}
    &::after{top: calc(100% - 20px);}
    &__title{
      margin-bottom: 22px;
      font-size: 44px;
      line-height: 52px;
    }
    &__logotyps{
      justify-content: space-between;
      margin-top: 106px;
      & > *{margin: 0;}
    }
    &__logo{
      height: 50px;
      img{height: 100%;}
    }
  }
  @media only screen and (max-width : 639px){
    &::after{
      width: 4000px;
      height: 4000px;
    }
    &__title{
      br{display: none;}
    }
    &__logo{height: 40px;}
  }
  @media only screen and (max-width : 479px){
    &__txt{
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 24px;
    }
    &__bar{
      display: block;
      & > *{
        margin: 16px 0 0;
        width: 100%;
      }
    }
    &__logotyps{
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 48px;
      & > *{
        margin: 0 16px;
        &:first-child{
          width: 100%;
          height: 48px;
          margin: 0 0 16px;
          img{margin: 0 auto;}
        }
      }
    }
    &__logo{height: 40px;}
    &__title{
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 24px;
    }
  }
}

.intro{
  padding-top: 70px;
  &__wrap{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 136px;
    position: relative;
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(48, 48, 48, 0.12), rgba(48, 48, 48, 0.12)), rgba(255, 255, 255, 0.12);
    }
  }
  &__col{width: calc(50% - 20px);}
  &__txt{
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: .25px;
    p:not(:last-child){margin-bottom: 32px;}
  }
  &__list{
    padding-top: 10px;
    li{
      position: relative;
      padding-left: 44px;
      margin-bottom: 32px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: .25px;
      &:last-child{margin-bottom: 0;}
      &::before{
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #2F66F2;
        top: 8px;
        left: 8px;
      }
    }
  }
  @media only screen and (max-width : 960px){
    padding-top: 90px;
    &__wrap{
      display: block;
      padding-bottom: 80px;
    }
    &__col{width: 100%;}
    &__txt{margin-bottom: 32px;}
    &__list{padding-top: 0;}
  }
  @media only screen and (max-width : 639px){
    padding-top: 64px;
    &__wrap{padding-bottom: 64px;}    
  }
  @media only screen and (max-width : 479px){
    &__txt{
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.footer{
  padding: 64px 0;
  background-color: #F4F6FA;
  &__wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }  
  @media only screen and (max-width : 960px){
    padding: 40px 0;
    &__wrap{
      flex-direction: column;
    } 
  }  
}
.footer-menu{
  ul{
    display: flex;
    align-items: center;
    justify-content: center;
    li{
      margin: 0 20px;
      &:first-child{margin-left: 0;}
      &:last-child{margin-right: 0;}
      a{
        color: #000;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: .6px;
        text-decoration: underline;
        white-space: nowrap;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  @media only screen and (max-width : 960px){
    margin: 20px 0;
  }
  @media only screen and (max-width : 639px){
    ul{
      li{margin: 0 10px;}
    }
  }
  @media only screen and (max-width : 479px){
    ul{
      li{margin: 0 6px;}
    }
  }
}

.faq{
  padding: 160px 0 120px;
  &__header{
    text-align: center;
    margin-bottom: 40px;
  }
  &__list{
    max-width: 760px;
    margin: 0 auto;
  }
  @media only screen and (max-width : 960px){
    padding: 200px 0 160px;
  }
  @media only screen and (max-width : 639px){
    padding: 162px 0 96px;
  }
  @media only screen and (max-width : 479px){
    padding-top: 130px;
  }
}
.faq-item{
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: .6px;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, .24);
  i{
    display: block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  svg{
    display: block;
    width: 100%;
    height: 100%;
    stroke: #000;
    transition: stroke .25s;
  }
  &:hover{
    border-bottom-color: #0088CC;
    color: #0088CC;
    svg{stroke: #0088CC;}
  }
}

.icons{
  padding: 160px 0;
  .sec-title{max-width: 560px;}
  &--2{
    .container{
      background: url(../img/svg/everscale-bg.svg) top right 20px no-repeat;
    }
  }
  @media only screen and (max-width : 1099px){
    &--2{
      .container{
        background-size: 380px auto;
      }
    }
  }
  @media only screen and (max-width : 960px){
    adding: 80px 0;
    .sec-title{max-width: 560px;}
    &--2{
      .container{background: none;}
    }
  }
  @media only screen and (max-width : 639px){
    padding: 64px 0;
  }
}
.icons-list{
  margin-top: 80px;
  display: grid;    
  grid-column-gap: 40px;
  grid-row-gap: 64px;
  &--3{grid-template-columns: repeat(3, 1fr);}
  &--4{grid-template-columns: repeat(4, 1fr);}
  @media only screen and (max-width : 960px){
    margin-top: 40px;
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    &--3,
    &--4{grid-template-columns: repeat(2, 1fr);}
  }
  @media only screen and (max-width : 639px){
    grid-column-gap: 16px;
  }
  @media only screen and (max-width : 479px){
    &--3{
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 0;      
    }
    &--4{
      .icons-item__title{
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
.icons-item{
  &__icn{margin-bottom: 20px;}
  &__title{
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: .15px;
  }
  &__txt{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .5px;
  }  
}

.planned{
  position: relative;
  padding: 180px 0 160px;
  background: radial-gradient(100% 100% at 0% 0%, rgba(99, 53, 240, 0.24) 0%, rgba(99, 53, 240, 0) 100%), radial-gradient(50% 100% at 100% 100%, rgba(99, 53, 240, 0.24) 0%, rgba(99, 53, 240, 0) 100%), #0C0C0C;
  color: #fff;
  overflow: hidden;
  &::after{
    content: '';
    display: block;
    position: absolute;
    bottom: calc(100% - 60px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    width: 11000px;
    height: 11000px;
  }
  &__wrap{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .sec-title span{white-space: nowrap;}
  &__roadmap,
  &__content{width: calc(50% - 20px);}
  &__txt{
    margin-top: 24px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .15px;
    max-width: 460px;
  }
  &__item{
    position: relative;
    padding-left: 44px;
    margin-bottom: 40px;
    &::before,
    &::after{
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      background-color: #2F66F2;
    }
    &::before{
      width: 8px;
      height: 8px;
      left: 8px;
    }
    &::after{
      left: 12px;
      top: 12px;
      opacity: .3;
      width: 1px;
      height: calc(100% + 40px);
    }
    &:last-child{
      margin-bottom: 0;
      &::after{display: none;}
    }
    ul{
      li{
        position: relative;
        margin-top: 10px;
        padding-left: 44px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: .25px;
        &::before{
          content: '';
          display: block;
          position: absolute;
          top: 8px;
          left: 8px;
          border-radius: 50%;
          background-color: #2F66F2;
          width: 8px;
          height: 8px;
        }
      }
    }
    &-sub{
      margin-top: 10px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: .25px;
      color: #ABABAB;
    }
    &-title{
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: .25px;
    }
  }
  @media only screen and (max-width : 960px){
    padding: 100px 0 80px;
    &__wrap{display: block;}
    &__roadmap,
    &__content{width: 100%;}
    &__content{margin-bottom: 40px;}
  }
  @media only screen and (max-width : 639px){
    padding: 86px 0 64px;
    &::after{
      width: 4000px;
      height: 4000px;
    }
  }
  @media only screen and (max-width : 479px){
    padding-top: 64px;
    &__item{
      &::before,
      &::after{top: 8px;}
      &-title{
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

.comparison{
  padding: 160px 0 127px;
  background-color: #F6F8FA;
  @media only screen and (max-width : 960px){
    padding: 80px 0;
  }
  @media only screen and (max-width : 639px){
    padding: 64px 0;
  }
}
.table-wrap{
  margin-top: 68px;
  overflow-x: auto;
  &::-webkit-scrollbar {display: none;}
  &::-webkit-scrollbar-track {display: none;}
  &::-webkit-scrollbar-thumb {display: none;}  
  @media only screen and (max-width : 960px){
    margin-top: 28px;
  }
  @media only screen and (max-width : 479px){
    padding: 0 10px;
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}
.table{
  border-collapse: collapse;
  min-width: 100%;
  thead{
    tr > *{
      border-bottom: 1px solid #000;
      vertical-align: middle;
    }
  }
  tbody{
    tr > *{
      border-bottom: 1px solid #EDEDED;
      white-space: nowrap;
    }
    tr:last-child{color: #2F66F2;}
  }
  td, th{
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: .5px;
    width: 25%;
    &:first-child{
      padding-left: 0;
      text-align: left;
    }
    &:last-child{padding-right: 0;}
  }
  th{font-weight: bold;}
  @media only screen and (max-width : 960px){
    thead tr th:first-child{font-size: 0;}
  }
}

.interoperability{
  background: radial-gradient(100% 100% at 0% 0%, rgba(99, 53, 240, 0.24) 0%, rgba(99, 53, 240, 0) 100%), radial-gradient(50% 100% at 100% 100%, rgba(99, 53, 240, 0.24) 0%, rgba(99, 53, 240, 0) 100%), #0C0C0C;
  color: #fff;
  &__wrap{
    padding: 160px 0; 
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: url(../img/svg/ready-bg.svg) 0 bottom no-repeat;
  }
  &__container{width: 460px;}
  &__list{width: 563px;}
  @media only screen and (max-width : 960px){
    &__wrap{
      padding: 80px 0;
      display: block;
      background: none;
    }
    &__container{
      width: 100%;
      margin-bottom: 40px;
    }
    &__list{width: 100%;}
  }
  @media only screen and (max-width : 639px){
    &__wrap{padding: 64px 0;}
  }
}
.interoperability-item{
  padding-left: 48px;
  position: relative;
  margin-bottom: 40px;
  &:last-child{margin-bottom: 0;}
  &::before{
    content: '';
    display: block;
    background: url(../img/svg/check-circle.svg) center center no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__title{
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: .15px;
    margin-bottom: 8px;
  }
  &__txt{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .5px;
  }  
}

.instruction{
  padding: 160px 0 280px;
  &__header{
    text-align: center;
    margin-bottom: 104px;
  }  
  @media only screen and (max-width : 960px){
    padding: 80px 0 160px;
    &__header{text-align: left;}
  }
  @media only screen and (max-width : 639px){
    padding: 64px 0 162px;
  }
  @media only screen and (max-width : 479px){
    padding-bottom: 130px;
  }
}
.instruction-sec{  
  margin-bottom: 120px;
  position: relative;
  &:last-child{margin-bottom: 0;}
  &__wrap{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 3;
  }
  &:nth-child(even) .instruction-sec__wrap{flex-direction: row-reverse;}
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: -64px;
  }
  &:nth-child(1)::before{
    right: 0;
    width: 300px;
    height: 566px;
    background: url(../img/svg/spot-1.svg) right 0 no-repeat;
  }
  &:nth-child(2)::before{
    left: 0;
    width: 300px;
    height: 740px;
    background: url(../img/svg/spot-2.svg) 0 0 no-repeat;
  }
  &:nth-child(3)::before{
    right: 0;
    width: 300px;
    height: 442px;
    background: url(../img/svg/spot-3.svg) right 0 no-repeat;
  }
  &__content,
  &__video{width: calc(50% - 20px);}
  &__content{max-width: 460px;}
  &__header{position: relative;}
  &__numb{
    position: absolute;
    bottom: 8px;
    left: 0;
  }
  &__title{
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: .25px;
    position: relative;
    z-index: 2;
  }
  &__list{
    counter-reset: num;
    li{
      margin-top: 24px;
      position: relative;
      padding-left: 28px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: .5px;
      color: #1B1B1B;
      .red{
        color: #E9615A;
        font-weight: 500;
        display: block;
        margin-top: .7em;
      }
      a{
        color: #1B1B1B;
        text-decoration: underline;
        &:hover{text-decoration: none;}
      }
      &::before {
        content: counter(num); 
        counter-increment: num;
        display: block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        text-align: center;
        background-color: #000;
        font-weight: 700;
        font-size: 14px;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
      }
    }    
  }
  @media only screen and (max-width : 1199px){
    &__content{width: 380px;}
    &__video{width: 460px;}
  }
  @media only screen and (max-width : 960px){
    margin-bottom: 105px;
    &__wrap{display: block;}
    &::before{display: none;}
    &__content,
    &__video{width: 100%;}
    &__content{
      max-width: unset;
      margin-bottom: 40px;
    }
  }
  
}
.video{
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.banner{
  &__wrap{
    position: relative;
    background: url(../img/svg/telegram-bg.svg) right center no-repeat, radial-gradient(100% 100% at 0% 0%, rgba(99, 53, 240, 0.24) 0%, rgba(99, 53, 240, 0) 100%), radial-gradient(50% 100% at 100% 100%, rgba(99, 53, 240, 0.24) 0%, rgba(99, 53, 240, 0) 100%), #0C0C0C;
    border-radius: 32px;
    color: #fff;
    padding: 80px 120px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::before,
    &::after{
      content: '';
      display: block;
      position: absolute; 
      user-select: none;
      pointer-events: none;
    }
    &::before{
      background: url(../img/svg/border.svg) 0 0 no-repeat;
      background-size: contain;
      width: 962px;
      height: 254px;
      top: -40px;
      left: 40px;
    }
    &::after{
      bottom: -40px;
      right: 0;
      height: 3px;
      background: linear-gradient(90deg, rgba(100, 144, 255, 0) 0%, #6490FF 49.44%, rgba(100, 144, 255, 0) 100%);
      width: 100%;
      max-width: 960px;
    }
  }
  &__bar{
    flex-shrink: 0;
    width: 272px;
    margin-left: 20px;
  }
  &__main{flex-grow: 1;}
  &__title{
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 44px;
    line-height: 52px;
    letter-spacing: .25px;
  }
  &__txt{
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .15px;
  }
  @media only screen and (max-width : 1199px){
    &__wrap{padding: 68px 80px;}
  }
  @media only screen and (max-width : 960px){
    &__wrap{
      flex-direction: column;
      padding: 60px 56px;
      text-align: center;
      &::before{
        background-image: url(../img/svg/border-640.svg);
        left: 30px;
        width: 592px;
        height: 254px;
      }
      &::after{width: 540px;}
    }
    &__main{margin-bottom: 40px;}
    &__bar{margin: 0;}
  }
  @media only screen and (max-width : 639px){
    &__wrap{
      padding: 64px 16px;
      &::after{
        bottom: -32px;
        max-width: unset;
        right: -18px;
        width: 432px;
      }
      &::before{
        background-image: url(../img/svg/border-480.svg);
        left: 30px;
        width: 450px;
        height: 252px;
      }
    }
    &__main{margin-bottom: 48px;}
  }
  @media only screen and (max-width : 479px){
    &__wrap{
      padding: 64px 8px;
      &::after{
        right: 0;
        width: 100%;
      }
      &::before{
        background-image: url(../img/svg/border-320.svg);
        left: 16px;
        z-index: -1;
        width: 290px;
        height: 254px;
      }
    }
    &__main{
      margin-bottom: 40px;
      padding: 0 24px;
    }
    &__title{
      font-size: 36px;
      line-height: 40px;
    }
  }
}

.distribution{
  color: #fff;
  padding-top: 120px;
  &__graphic{
    margin-top: 60px;
    &-title{
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: .15px;
    }
  }
  &__title{
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: .25px;
  }
  &__wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media only screen and (max-width : 959px){
    &__wrap{display: block;}
  }
  @media only screen and (max-width : 767px){  
    padding-top: 80px;
  }
  @media only screen and (max-width : 400px){
    &__graphic{
      &-title{margin-bottom: 0;}
    }
  }
}
.distribution-item{
  position: relative;
  height: 360px;
  &__ring{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 60px;
    z-index: 3;
    width: 310px;
    height: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0 0 40px 7px rgba(0, 56, 255, 0.2);
    }
  }
  &__title{
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: .15px;
    padding: 0 30px;
    span{
      display: block;
      margin-top: 4px;
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: .25px;
    }
  }
  &__legend{
    &-title{
      margin-bottom: 2px;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: .25px;
      white-space: nowrap;
    }
    &-val{
      white-space: nowrap;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: .6px;
      span{
        margin-left: 8px;
        color: rgba(255, 255, 255, .56);
      }
    }
  }
  &__left{
    position: absolute;
    bottom: 0;
    left: 0;
    &::before{
      content: '';
      display: block;
      background: url(../img/svg/lines-1.svg) center center no-repeat;
      width: 64px; 
      height: 99px;
      position: absolute;
      bottom: 100%;
      left: 5px;
    }
  }
  &__right{
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    &::before{
      content: '';
      display: block;      
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
    }
    &--1{
      left: 436px;
      height: 272px;
    }
    &--2{
      left: 392px;
      height: 230px;
    }
    &--1::before{
      background: url(../img/svg/lines-2.svg) center center no-repeat;
      width: 217px; 
      height: 247px;
      }
    &--2::before{
      background: url(../img/svg/lines-3.svg) center center no-repeat;
      width: 233px; 
      height: 228px;
      margin-top: 12px;
    }
  }
  &:nth-child(1){
    width: calc(100% - 500px);
    max-width: 660px;
  }
  &:nth-child(2){
    width: 500px;
    .distribution-item__ring{left: 12px;}
  }
  @media only screen and (max-width : 1149px){   
    &__ring{
      width: 258px;
      height: 258px;
    }
    &__right{
      &::before{transform: translate(10%,-50%) scaleX(.8);}
      &--1{left: 382px;}
      &--2{
        left: 304px;
        top: 10px;
      }
    }
    &:nth-child(1){
      width: calc(100% - 382px);
      max-width: 528px;
    }
    &:nth-child(2){width: 382px;}
  }  
  @media only screen and (max-width : 959px){
    &__ring{
      width: 310px;
      height: 310px;
    }
    &__right{
      &::before{transform: translateY(-50%);}
      &--1{left: 436px;}
      &--2{
        left: 436px;
        top: 0;        
      }
    }
    margin-left: auto;
    margin-right: auto;
    &:nth-child(1){
      width: 580px;
      max-width: unset;
      margin-bottom: 40px;
    }
    &:nth-child(2){
      width: 580px;
      .distribution-item__ring{left: 60px;}
    }
  }
  @media only screen and (max-width : 639px){
    transform: scale(.85);
    transform-origin: 0 0;
    &:nth-child(1){margin-bottom: 0;}
    &:nth-child(2){margin-bottom: -40px;}
  }
  @media only screen and (max-width : 539px){
    transform: scale(.75);
    &:nth-child(1){margin-bottom: -40px;}
    &:nth-child(2){margin-bottom: -80px;}
  }
  @media only screen and (max-width : 479px){
    transform: scale(.65);
    &:nth-child(1){margin-bottom: -60px;}
    &:nth-child(2){margin-bottom: -100px;}
  }
  @media only screen and (max-width : 410px){
    transform: scale(.55);
    &:nth-child(1){margin-bottom: -120px;}
    &:nth-child(2){margin-bottom: -160px;}
  }
  @media only screen and (max-width : 359px){
    transform: scale(.5);
    &:nth-child(1){margin-bottom: -140px;}
    &:nth-child(2){margin-bottom: -180px;}
  }
}
.distribution-graphic{
  padding-bottom: 40px;
  border-left: 1px solid rgba(255, 255, 255, .32);
  border-bottom: 1px solid rgba(255, 255, 255, .32);
  width: calc(100% - 28px);
  margin-left: 28px;
  position: relative;
  color: rgba(255, 255, 255, .64);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: .6px;
  font-weight: 500;
  &__main{
    height: 342px;
    width: 100%;
    img{
      width: calc(100% + 20px);
      left: -5px;
      height: 100%;
      max-width: unset;
      position: relative;
      z-index: 2;
    }
    span{
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 2.6%, rgba(255, 255, 255, 0.12) 9.9%);
      &:nth-child(1){top: 4%;}
      &:nth-child(2){top: 27.5%;}
      &:nth-child(3){top: 47.5%;}
      &:nth-child(4){top: 67.5%;}
      &:nth-child(5){top: 87.5%;} 
    }
  }
  &__y-title{
    position: absolute;
    bottom: 0;
    left: -12px;
    transform-origin: 0 100%;
    transform: rotate(-90deg);
  }
  &__x-title{
    position: absolute;
    bottom: -28px;
    left: 0;
  }
  &__x{
    span{
      display: block;
      text-align: center;
      position: absolute;
      bottom: 10px;
      margin-left: 9px;
      transform: translateX(-50%);
      &:nth-child(1){left: 3%;}
      &:nth-child(2){left: 16%;}
      &:nth-child(3){left: 29.5%;}
      &:nth-child(4){left: 43%;}
      &:nth-child(5){left: 56.5%;}
      &:nth-child(6){left: 70%;}
      &:nth-child(7){left: 83%;}
      &:nth-child(8){left: 96.5%;}
    }
  }
  &__y{
    span{
      display: block;
      position: absolute;
      left: 6px;
      z-index: 3;
      &:nth-child(1){top: 2%;}
      &:nth-child(2){top: 25.5%;}
      &:nth-child(3){top: 45.5%;}
      &:nth-child(4){top: 65.5%;}
      &:nth-child(5){top: 75.5%;}      
      &:nth-child(6){top: 86.5%;}      
    }
  }
  @media only screen and (max-width : 400px){
    transform: scale(.7);
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-bottom: -40px;
  }  
}